import { defineStore } from "pinia";

export const useSupportStore = defineStore('support',{
    state:()=>{
        return {
            tickets:[],
            currentTicket:{},
            totalCount:null
        }
    },
    actions:{
        setTickets(data,page){
            this.tickets = data
        },
        setTotalCount(count){
            this.totalCount = count
        },
        setTicket(data){
            this.currentTicket = data
        },
        addTicket(data){
            this.tickets.unshift(data)
        },
        addTickets(data){
            this.tickets.unshift(...data)
        },
        deleteTicket(id){
           const ticketIndex = this.tickets.findIndex(ticket=>ticket.id === id);
           this.tickets.splice(ticketIndex,1)
        },
        resetSupportStore(){
            this.tickets=[]
            this.currentTicket={}
            this.totalCount=null
        }
    },
    getters:{
        getTickets:(state)=>{
            return state.tickets
        },
        getTicket:(state)=>{
            return state.currentTicket
        },
        getTotalCount:(state)=>{
            return state.totalCount
        }
    }
})